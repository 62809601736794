<template>
  <EventManagementPage
    class="ticket-buyer"
    title=""
    help-video="https://youtube.com/embed/FNtV7jVN1Jg"
    :breadcrumbs="breadcrumbs"
    @load="onLoad"
  >
    <note-modal v-on:save="saveNote" ref="notesModal" />
    <Accordion title="Create Manual Order" v-model="showOrderForm">
      <TabTote :tabs="tabs" v-model="tab" />
      <ImportCustomOrders v-if="tab === 1"
                          :promoters="promoters"
                          :is-recurring-rvent="isRecurringEvent"
                          :tiers-data="tiersData"
                          :event="event"
                          :tier-list="tierList"
                          :products="products"
                          />
      <FormulateForm
        ref="form"
        @submit="validateOrderForm"
        v-model="orderForm"
        name="manualOrderForm"
        v-show="tab === 0"
        style="margin-top: 10px"
      >
        <div class="row">
          <div class="col half">
            <FormulateInput
              label="First Name"
              name="firstName"
              type="text"
              placeholder="First Name"
              validation="required"
            />
          </div>
          <div class="col half">
            <FormulateInput
              label="Last Name"
              name="lastName"
              type="text"
              placeholder="Last Name"
              validation="required"
            />
          </div>
          <div class="col half">
            <FormulateInput
              name="email"
              label="Email"
              type="email"
              placeholder="Enter your email address"
              validation="required|email"
            />
          </div>
          <div class="col half">
            <FormulateInput
              label="Phone"
              name="phoneNumber"
              type="tel"
              placeholder="Phone Number"
            />
          </div>
          <div class="col half">
            <FormulateInput
              label="Location"
              name="location"
              type="text"
              placeholder="Country of Residence"
            />
          </div>
          <div class="col half">
            <FormulateInput
              type="selectSearch"
              label="Promoter"
              currency="true"
              :options="promoters"
              name="promoter"
              placeholder="Please Select promoter"
              autocomplete="false"
            />
          </div>
          <div class="col half">
            <FormulateInput
              label="Company"
              name="company"
              type="text"
              placeholder="Company"
            />
          </div>
          <div class="col half">
            <FormulateInput
              label="Language"
              name="language"
              type="text"
              placeholder="Language"
            />
          </div>
          <div class="col half">
            <FormulateInput
              label="Order Date"
              name="orderDate"
              type="dateTime"
              placeholder="Select Date"
            />
          </div>
        </div>

        <div class="row">
          <FormulateInput
            type="group"
            name="tiers"
            label="Tiers"
            :repeatable="true"
            add-label="+ Add Tiers"
            :value="orderForm.tiers"
            #default="{ index }"
            autocomplete="false"
          >
            <div class="col quarterx3 ticket-tier-container">
              <TicketTierSelector
                v-if="isRecurringEvent"
                :error="false"
                :tiers="tiersData"
                v-model="tierholders[index]"
                @input="addRecurringTier($event, index)"
              />
              <FormulateInput
                v-else
                type="select"
                label="Ticket tier"
                :options="tierList"
                name="saleDateId"
                placeholder="Please Select"
                :bundle="true"
                @input="addOrderTier($event, index)"
                autocomplete="false"
              />
            </div>
            <div class="col quarter ticket-tier-quantity">
              <FormulateInput
                type="number"
                label="Quantity"
                name="quantity"
                autocomplete="false"
                @input="tierQty($event, index)"
              />
            </div>
          </FormulateInput>
        </div>
        <div class="row">
          <FormulateInput
            type="group"
            name="products"
            :repeatable="true"
            label="Products"
            add-label="+ Add Products"
            :value="orderForm.products"
            #default="{ index }"
            autocomplete="false"
          >
            <div class="col quarterx3 ticket-tier-container">
              <FormulateInput
                type="select"
                label="Product"
                :options="products"
                name="variantId"
                placeholder="Please Select"
                :bundle="true"
                @input="addProducts($event, index)"
                autocomplete="false"
              />

              <!-- <span class="ticket-tier-plus-sign">+</span> -->
            </div>
            <div class="col quarter ticket-tier-quantity">
              <FormulateInput
                type="number"
                label="Quantity"
                name="quantity"
                autocomplete="false"
              />
            </div>
            <!-- <FormulateInput
              type="money"
              label="Discount"
              :placeholder="methodTypePlaceholderDisplay"
              validation="required"
              name="methodValue"
            /> -->
          </FormulateInput>
        </div>
        <div class="amount">
          <span>Total: {{ orderAmount }}</span>
        </div>
<!--        useEmails-->
        <FormulateInput
          type="checkbox"
          name="useEmails"
          label="Send notification emails to user"
          autocomplete="false"
        />
        <FormulateInput
          class="form-submit"
          name="Add Order"
          type="submit"
        ></FormulateInput>
      </FormulateForm>
    </Accordion>
    <buyer-list-modal
      ref="detailsModal"
      :isReservedSeatsType="isReservedSeats"
      @editOrder="editOrder"
      @update-promoter="updatePromoter"
      @issueRefund="refundOrder"
    />

    <section>
      <div class="row">
        <div class="col full">
          <!-- <table-tote
            v-model="tableData"
            :dateSearch="true"
            dateFrom="From"
            dateTo="To"
            v-on:modal="notesOpen"
            v-on:modalTwo="operBuyerInfo"
          /> -->

          <DataTable
            title="Buyer's List"
            endpoint="/planners/event-tickets-list/:event"
            csv-link="/planners/event-tickets-list/:event/csv"
            :args="{ event: eventUrl }"
            :params="{ letterSearch: currentLetter }"
            ref="table"
            :headers="this.tableContent.columns"
            :custom-columns="[
              'notes',
              'details',
              'isRefunded',
              'resendAttReminder'
            ]"
            :csvMapper="ticketsCSVMapper"
            searchPlaceholder="Name or Email Search"
          >
            <template v-slot:[`item.isRefunded`]="{ item }">
              <span>{{ item.refunded || "-" }}</span>
            </template>
            <template v-slot:[`item.notes`]="{ item }">
              <v-icon @click="notes(item)">mdi-pencil</v-icon>
            </template>
            <template v-slot:[`item.details`]="{ item }">
              <v-icon @click="details(item)">mdi-information-outline</v-icon>
            </template>
            <template v-slot:[`item.resendAttReminder`]="{ item }">
              <v-icon @click="resendAttendeeReminder(item)">mdi-replay</v-icon>
            </template>
          </DataTable>

          <DataTable
            title="Comps/Willcall List"
            endpoint="/planners/event-comps-tickets-list/:event"
            :args="{ event: eventUrl }"
            :params="{ letterSearch: currentLetter }"
            ref="table"
            :headers="this.tableContent.columns"
            :custom-columns="['notes', 'details', 'isRefunded']"
            :csvMapper="ticketsCSVMapper"
            searchPlaceholder="Name or Email Search"
          >
            <template v-slot:[`item.isRefunded`]="{ item }">
              <span>{{ item.refunded || "-" }}</span>
            </template>
            <template v-slot:[`item.notes`]="{ item }">
              <v-icon @click="notes(item)">mdi-pencil</v-icon>
            </template>
            <template v-slot:[`item.details`]="{ item }">
              <v-icon @click="details(item)">mdi-information-outline</v-icon>
            </template>
          </DataTable>

          <DataTable
            title="Print Ticket List"
            endpoint="/planners/event-print-tickets-list/:event"
            :args="{ event: eventUrl }"
            :params="{ letterSearch: currentLetter }"
            ref="table"
            :headers="this.tableContent.columns"
            :custom-columns="['notes', 'details', 'isRefunded']"
            :csvMapper="ticketsCSVMapper"
            searchPlaceholder="Name or Email Search"
          >
            <template v-slot:[`item.isRefunded`]="{ item }">
              <span>{{ item.refunded || "-" }}</span>
            </template>
            <template v-slot:[`item.notes`]="{ item }">
              <v-icon @click="notes(item)">mdi-pencil</v-icon>
            </template>
            <template v-slot:[`item.details`]="{ item }">
              <v-icon @click="details(item)">mdi-information-outline</v-icon>
            </template>
          </DataTable>
          <!-- <base-table
            type="tixTable"
            endpoint="planners/event-tickets-list"
            :params="{ event: eventUrl }"
            title="Buyer's List"
            :columns="this.tableContent.columns"
            :tabs="tabs"
            :alphabetSearch="true"
            tableType="buyerlist"
            :tableSize="tablesize"
            :dataCount="dataCount"
            @notesModal="notesOpen"
            thead-classes="text-primary"
          /> -->
        </div>
      </div>
      <div class="row">
        <div class="col full">
          <DataTable
            title="Pre Sale List"
            endpoint="/planners/pre-sale-users/:event"
            :args="{ event: eventUrl }"
            :headers="this.presaleTable.columns"
          >
            <!-- <template v-slot:[`item.created_at`]="{ item }">
              {{
                new Date(item.created_at).toLocaleDateString([], {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit"
                })
              }}
            </template> -->
          </DataTable>
        </div>
      </div>

      <div class="row">
        <div class="col full">
          <DataTable
            ref="prod"
            :title="this.productTableData.title"
            endpoint="planners/event-products-list/:event"
            :args="{ event: eventUrl }"
            :params="{ letterSearch: currentLetter }"
            :headers="this.productTableData.columns"
            :custom-columns="['notes']"
            searchPlaceholder="Name or Email Search"
          >
            <template v-slot:[`item.notes`]="{ item }">
              <v-icon @click="notes(item)">mdi-pencil</v-icon>
            </template>
          </DataTable>
          <!--          <base-table-->
          <!--            type="tixTable"-->
          <!--            endpoint="planners/event-products-list"-->
          <!--            :params="{ event: eventUrl }"-->
          <!--            :title="this.productTableData.title"-->
          <!--            :columns="this.productTableData.columns"-->
          <!--            :tableSize="tablesize"-->
          <!--            :dataCount="dataCount"-->
          <!--            @notesModal="notes"-->
          <!--            thead-classes="text-primary"-->
          <!--          />-->
        </div>
      </div>
      <div class="row">
        <div class="col full">
          <DataTable
            title="Event Registrations"
            endpoint="/planners/event-registration-list/:event"
            :args="{ event: eventUrl }"
            :headers="this.eventRegistrationTable.columns"
            :total-count="true"
          ></DataTable>
        </div>
      </div>
    </section>
  </EventManagementPage>
</template>

<style lang="less" scoped>
.accordion {
  overflow: visible;
}

.amount {
  margin-bottom: 10px;
}

.ticket-buyer {
  #Content {
    .content-inner {
      .button-container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
      }

      .buyer-wrapper {
        display: flex;
        justify-content: flex-end;
        margin: 0px 0px 10px 0px;
      }

      .headers {
        left: 10px;
        color: #43b777;
        font-weight: 400;
        font-size: larger;

        .inner-content {
          // color: var(--text);
          font-weight: 700;
          font-size: large;
        }

        .info-box {
          order: 4px solid #43b777;
          border-radius: 12px;
          background: var(--primary-green);
          color: white;
          padding: 8px;
          font-size: 0.875em;
          margin: 16px 0;
        }
      }

      .button-wrapper {
        position: relative;
        bottom: -10px; // bottom: -89px;
        .diyobo-button {
          padding: 8px 33px 6px 11px;
          //border-radius: 10px;
        }

        .print-icon {
          position: absolute;
          top: 15px;
          left: 117px;
          z-index: 1;
          cursor: pointer;
        }
      }

      ::-webkit-scrollbar {
        width: 0px; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
      }

      /* Optional: show position indicator in red */

      ::-webkit-scrollbar-thumb {
        background: transparent;
      }
    }

    // end content-inner
  }

  // end of content
}

.ticket-tier-container {
  display: flex;
  margin-right: 16px;
  // grid-template-columns: 95% 5%;
}
</style>
<script>
import Accordion from "@/components/Accordion.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPrint, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import TableTote from "@/components/TableTote.vue";
import dateFormat from "dateformat";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import NoteModal from "../components/modals/NotesModal.vue";
import EventManagementPage from "../components/EventManagementPage";
import dayjs from "dayjs";
import buyerInfoModal from "../components/modals/BuyerInfoModal.vue";
import BaseTable from "@/components/Tables/BaseTable.vue";
import DataTable from "@/components/DataTable.vue";
import BuyerListModal from "../components/modals/BuyerListModal.vue";
import TicketTierSelector from "@/components/TicketTierSelector.vue";
import TabTote from "@/components/TabTote.vue";
import ImportCustomOrders from "../components/ImportCustomOrders.vue"

import LocalizedFormats from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

library.add(faPrint);

dayjs.extend(LocalizedFormats);
dayjs.extend(timezone);
dayjs.extend(utc);
export default {
  name: "event-tickets-list",
  components: {
    Accordion,
    EventManagementPage,
    DiyoboInput,
    DiyoboButton,
    TableTote,
    TabTote,
    ImportCustomOrders,
    FontAwesomeIcon,
    Breadcrumbs,
    NoteModal,
    buyerInfoModal,
    BaseTable,
    DataTable,
    BuyerListModal,
    TicketTierSelector
  },
  head() {
    return {
      title:
        this.eventdata && this.eventdata.name
          ? `${this.eventdata.name} Buyers List`
          : "Buyers List"
    };
  },
  data() {
    return {
      loading: false,
      event: [],
      tablesize: 10,
      page: 1,
      dataCount: 0,
      currentLetterPlace: null,
      alphabetDrop: [
        "All",
        "ABC",
        "DEF",
        "GHI",
        "JKL",
        "MNO",
        "PQRS",
        "TUVW",
        "XYZ"
      ],
      showOrderForm: false,
      all: ["all"],
      alphabets: [],
      currentLetter: null,
      tableContent: {
        title: "Buyer's Ticket List",
        columns: [
          { text: "Buyer Name", value: "name" },
          { text: "Quantity", value: "ticket_count" },
          { text: "Price Paid", value: "price_paid", format: "currency" },
          { text: "Buyer Email", value: "account_email" },
          { text: "Order Date", value: "createdAt" },
          // { text: "Type", value: "type" },
          // { text: "Language", value: "preferred_language" },
          {
            text: "Notes",
            value: "notes",
            modal: true,
            modalTitle: "View/Add"
          },
          { text: "Refunded", value: "isRefunded" },
          {
            text: "Details",
            value: "details"
          }
        ],
        tab: 0,
        tabs: ["ORDER DETAILS", "TICKET DETAILS", "ADDITIONAL DETAILS"]
      },
      presaleTable: {
        columns: [
          { text: "First Name", value: "firstName", width: "20%" },
          { text: "Last Name", value: "lastName", width: "20%" },
          { text: "Email", value: "email", align: "20%" },
          { text: "Phone", value: "phoneNumber", align: "20%" },
          { text: "Created", value: "createdAt", align: "20%" }
        ]
      },
      eventRegistrationTable: {
        columns: [
          { text: "First Name", value: "first_name", width: "20%" },
          { text: "Last Name", value: "last_name", width: "20%" },
          { text: "Email", value: "email", align: "20%" },
          { text: "Promoter", value: "promoter_name", align: "20%" },
          { text: "Created", value: "created_at", align: "20%" }
        ]
      },
      productTableData: {
        title: "Product Buyers List",
        columns: [
          { text: "Product", value: "name" },
          { text: "Quantity", value: "quantity" },
          { text: "Price Paid", value: "price", format: "currency" },
          { text: "Buyer Name", value: "purchaser" },
          { text: "Order Date", value: "order_date" },
          { text: "Email", value: "email" },
          { text: "Phone Number", value: "phone" },
          {
            text: "Notes",
            value: "notes",
            modal: true,
            modalTitle: "View/Add"
          }
        ]
      },

      footerProps: {
        "items-per-page-options": [10, 25, 50, 100],
        "show-current-page": true,
        "show-first-last-page": true
      },
      breadcrumbs: [
        ["Dashboard", "/dashboard"],
        ["Ticket Lists", null]
      ],
      eventdata: {},
      result: [],
      eventUrl: this.$route.params.url,
      tableData: {},

      notesModal: false,
      detailsModal: false,
      detailsIndex: 0,
      detailsItem: {},
      ticket: {},
      buyerInfoModal: false,
      orderCounter: 0,
      isFetchData: true,
      intervalStop: null,
      isReservedSeats: false,
      orderForm: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        location: "",
        language: "",
        company: "",
        tiers: [],
        products: [],
        promoter: "",
        useEmails: false
      },
      isRecurringEvent: false,
      tiersData: [],
      tierList: [],
      productsData: [],
      tierholders: [],
      products: [],
      promoters: [],
      TFeePriceRange: [],
      tabs: ["Manual Entry", "Upload CSV"],
      tab: 0,
    };
  },
  methods: {
    addOrderTier(data, index) {
      let ogTier = this.tiersData.find(t => t.saleDateId === data);
      // ogTier.type = data.type;
      ogTier.tierId = ogTier.id;
      ogTier.venueAccessDateID = data.venueAccessDateID;
      ogTier.venueAccessTimeID = data.venueAccessTimeID;
      ogTier.quantity = 1;
      ogTier.dfee = 0;

      if (this.event.companyUrl != "nmp") {
        if (this.event.buyerOrPlannerPaysFees == "buyerPaysFees") {
          this.TFeePriceRange.forEach(fee => {
            if (
              ogTier.price >= fee.startAmount &&
              ogTier.price <= fee.endAmount
            ) {
              if (
                ogTier.ticketType == "IN_PERSON" ||
                ogTier.ticketType == "IN_PERSON_RESERVED"
              ) {
                ogTier.dfee = fee.InPerson;
              } else if (ogTier.ticketType == "VIRTUAL") {
                ogTier.dfee = fee.Virtual;
              } else if (ogTier.ticketType == "DONATION") {
                ogTier.dfee = fee.Donations;
              }
            }
          });
        }
      }
      this.orderForm.tiers[index] = ogTier;
    },
    tierQty(qty, index) {
      if (
        this.orderForm.tiers[index].saleDates &&
        qty >
          this.orderForm.tiers[index].saleDates[0].qty -
            this.orderForm.tiers[index].saleDates[0].start
      ) {
        this.orderForm.tiers[index].quantity =
          this.orderForm.tiers[index].saleDates[0].qty -
          this.orderForm.tiers[index].saleDates[0].start;
      } else {
        this.orderForm.tiers[index].quantity = qty;
      }
    },
    addRecurringTier(data, index) {
      let ogTier = this.tiersData.find(t => t.saleDateId === data);
      ogTier.type = data.type;
      ogTier.tierId = ogTier.id;
      ogTier.venueAccessDateID = data.venueAccessDateID;
      ogTier.venueAccessTimeID = data.venueAccessTimeID;
      ogTier.quantity = 1;
      ogTier.dFee = 0;

      if (this.event.companyUrl != "nmp") {
        if (this.event.buyerOrPlannerPaysFees == "buyerPaysFees") {
          this.TFeePriceRange.forEach(fee => {
            if (
              ogTier.price >= fee.startAmount &&
              ogTier.price <= fee.endAmount
            ) {
              if (
                ogTier.ticketType == "IN_PERSON" ||
                ogTier.ticketType == "IN_PERSON_RESERVED"
              ) {
                ogTier.dFee = fee.InPerson;
              } else if (ogTier.ticketType == "VIRTUAL") {
                ogTier.dFee = fee.Virtual;
              } else if (ogTier.ticketType == "DONATION") {
                ogTier.dFee = fee.Donations;
              }
            }
          });
        }
      }
      this.orderForm.tiers[index] = ogTier;
    },
    addProducts(data, index) {
      let ogProduct;
      // TODO Do duplicate validation
      ogProduct = this.productsData.find(product => product.variantId === data);
      ogProduct.productId = ogProduct.product_id.$oid;
      ogProduct.bundlePrice = parseInt(ogProduct.price);
      ogProduct.quantity = 1;
      ogProduct.dFee = 0;
      if (this.event.buyerOrPlannerPaysFees == "buyerPaysFees") {
        this.TFeePriceRange.forEach(fee => {
          if (
            ogProduct.price >= fee.startAmount &&
            ogProduct.price <= fee.endAmount
          ) {
            ogProduct.dFee = fee.Products;
          }
        });
      }
      this.orderForm.products[index] = ogProduct;
    },
    ticketsCSVMapper(orders) {
      let ticketList = [];
      var trueTypeOf = obj =>
        Object.prototype.toString
          .call(obj)
          .slice(8, -1)
          .toLowerCase();
      orders.forEach(order => {
        let fields = null;
        if (order.custom_fields) {
          fields = order.custom_fields.reduce((a, b) => {
            a[`Question: ${b.question}`] = b.answers.join(", ");
            return a;
          }, {});
        }

        if (trueTypeOf(order.tickets) !== "array") {
          return;
        }

        order.tickets.forEach(ticket => {
          // if (ticket.refunded) {
          //   return;
          // }
          // If no total
          if (!ticket.total) {
            ticket.total = 0;
          }
          // If total is 0 reset from tier price
          if (ticket.total === 0 || ticket.total.$numberDecimal === 0) {
            ticket.total = ticket.price;
          }
          if (ticket.refundAmount && ticket.refundAmount.$numberDecimal) {
            ticket.refundAmount = ticket.refundAmount.$numberDecimal;
          }
          let list = {
            "Order Date": order.createdAt,
            Refunded: ticket.refunded
              ? "Yes"
              : ticket.refundAmount > 0
              ? "Partial"
              : "No",
            Transferred: order.transferred ? "Yes" : "No",
            "Ticket #": ticket.number,
            "Tier Name": ticket.tierName,
            "Section/Seat": ticket.seats,
            "First Name": order.first_name,
            "Last Name": order.last_name,
            Email: order.account_email || order.email,
            Waiver: ticket.waiver_signed ? "Signed" : "Not Signed",
            Phone: order.phone,
            "Address 1": order.address1,
            City: order.city,
            Language: order.preferred_language,
            Company: order.associated_company || "Other",
            "Ip Address": order.buyer_ip_information
              ? order.buyer_ip_information.ip
              : "N/A",
            "IP City": order.buyer_ip_information
              ? order.buyer_ip_information.city
              : "",
            "IP Country": order.buyer_ip_information
              ? order.buyer_ip_information.country_name
              : "",
            "Attendee Name": ticket.attendee ? ticket.attendee.name : "N/A",
            "Attendee Email": ticket.attendee ? ticket.attendee.email : "",
            "Magic Link": ticket.magic_link ? ticket.magic_link : "",
            "Attendee Phone": ticket.attendee ? ticket.attendee.phone : "",
            "Attendee Ip Address": this.hasValueNested(
              order,
              "attendee.attendee_ip_information.ip"
            )
              ? order.attendee.attendee_ip_information.ip
              : "",
            "Attendee Ip Country": this.hasValueNested(
              order,
              "attendee.attendee_ip_information.country_name"
            )
              ? order.attendee.attendee_ip_information.country_name
              : "",
            "Attendee Ip City": this.hasValueNested(
              order,
              "attendee.attendee_ip_information.city"
            )
              ? order.attendee.attendee_ip_information.city
              : "",
            "Discount Type": order.discount_type,
            "Discount name": order.discount_name,
            "Ticket Subtotal":
              ticket.price && ticket.price.$numberDecimal
                ? ticket.price.$numberDecimal
                : ticket.price,
            // "Ticket Total":
            //   ticket.total && ticket.total.$numberDecimal
            //     ? ticket.total.$numberDecimal
            //     : ticket.total,
            TFee:
              ticket.dfee && ticket.dfee.$numberDecimal
                ? ticket.dfee.$numberDecimal
                : ticket.dfee,
            "Processing Fee": order.total_cc_fees || "0",
            "Receipt Total": order.grand_total,
            "Promoter Name": order.promoter_name || "",
            "Ticket Type": ticket.ticketType,
            ...fields,
            // "Custom Field 1": "",
            // "Custom Field 2": "",
            // "Custom Field 3": "",
            // "Custom Field 4": "",
            // "Custom Field 5": "",
            // "Custom Field 6": ""
          };
          if (this.isReservedSeats) {
            list["Table Seats"] = ticket.seats;
          }
          ticketList.push(list);
        });
      });

      return ticketList;
    },
    hasValueNested(obj, key) {
      return key.split(".").every(function(x) {
        if (typeof obj != "object" || obj === null || !x in obj) return false;
        obj = obj[x];
        return true;
      });
    },
    letterSearch(letter) {
      if (letter !== "ALL") {
        this.currentLetter = this.alphabets[letter];
      } else {
        this.currentLetter = null;
      }
    },
    notes(item) {
      this.detailsItem = Object.assign({}, item);
      this.$refs.notesModal.open(item);
    },
    resendAttendeeReminder(item) {
      this.$axios
        .post("/attendee/resend-reminder", {
          order_id: item._id.$oid
        })
        .then(response => {
          this.$toast(response.data.message, { type: "success" });
        });
    },
    details(item) {
      // this.detailsIndex = this.data.indexOf(item);
      this.detailsItem = Object.assign({}, item);
      this.$refs.detailsModal.open(item);
    },
    operBuyerInfo(ticket) {
      this.ticket = ticket;
      this.buyerInfoModal = true;
    },
    closeBuyerInfo() {
      this.buyerInfoModal = false;
    },
    notesOpen(ticket) {
      this.ticket = ticket;
      this.notesModal = true;
    },
    notesClose() {
      this.notesModal = false;
    },
    saveNote(ticket, note) {
      this.$axios
        .post("/tickets/add-note", {
          id: ticket.order_id ? ticket.order_id : ticket._id,
          type: ticket.type,
          notes: note
        })
        .then(response => {
          this.$toast("Notes added.", { type: "success" });
        });
    },
    updatePromoter(input) {
      let ticket = this.detailsItem;
      this.$axios
        .post("/planners/update-promoter", {
          orderId: ticket.order_id ? ticket.order_id : ticket._id,
          promoter: input
        })
        .then(response => {
          this.$toast("Promoter Updated", { type: "success" });
          this.$refs.detailsModal.close();
          this.$refs.table.refetchData();
        });
    },
    editOrder(order) {
      let editedOrder = {
        first_name: order.first_name,
        last_name: order.last_name,
        email: order.account_email,
        phone: order.phone
      };
      this.$axios
        .post(`planners/order/${order._id.$oid}/edit`, editedOrder)
        .then(response => {
          this.$toast("Order Updated.", { type: "success" });
          this.$refs.table.refetch();
        });
    },
    refundOrder(order) {
      let postObj = {
        orderId: order._id.$oid,
        orderEmail: order.account_email,
        eventName: this.event.name,
        plannerName: this.$store.state.user.name,
        refundType: order.refundType,
        refundAmount: order.refundAmount
      };

      this.$axios
        .post(`planners/order/${order._id.$oid}/refund`, postObj)
        .then(response => {
          this.$toast("Order Refunded.", { type: "success" });
          this.$refs.table.refetch();
        });
    },
    async fetchData() {
      // if (this.isFetchData) {
      //   try {
      //     const { data } = await this.$axios.post(
      //       "/planners/event-tickets-list",
      //       {
      //         event: this.eventUrl,
      //         orderCounter: this.orderCounter,
      //         isComps: false,
      //         isPrint: false
      //       }
      //     );
      //     console.log(data.orderCounter);
      //     this.orderCounter = data.orderCounter;
      //     if (data.tickets.length > 0) {
      //       const tickets = data.tickets.map((t) => {
      //         if (t.date && t.date.$date) {
      //           t.date = dateFormat(
      //             +t.date.$date.$numberLong,
      //             "ddd, mmm dS, yyyy"
      //           );
      //         }
      //         t.ticket_date = "";
      //         t.ticket_time = "";
      //         if (t.tier_date && t.tier_date.$date) {
      //           if (t.tier_type === "Date" || t.tier_type === "Date and Time") {
      //             t.ticket_date = dayjs(+t.tier_date.$date.$numberLong).format(
      //               "MMM DD, YYYY"
      //             );
      //           }
      //           if (t.tier_type === "Date and Time") {
      //             t.ticket_time = dayjs(+t.tier_date.$date.$numberLong).format(
      //               "h:mm A"
      //             );
      //           }
      //         }
      //         //create empty row in csv
      //         t.empty = " ";
      //         //end
      //         return t;
      //       });
      //       const products = data.products.map((p) => {
      //         if (p.order_date.$date) {
      //           p.order_date = dateFormat(
      //             +p.order_date.$date.$numberLong,
      //             "ddd, mmm dS, yyyy"
      //           );
      //         }
      //         return p;
      //       });
      //       this.tableData.data = [...this.tableData.data, ...tickets];
      //       this.tableData.productTableData = [
      //         ...this.productTableData.data,
      //         ...products
      //       ];
      //       this.$forceUpdate();
      //     } else {
      //       clearInterval(this.intervalStop);
      //       this.isFetchData = false;
      //       this.intervalStop = null;
      //     }
      //   } catch (error) {
      //     console.log(error);
      //   }
      // }
    },
    onLoad({ dateID }) {
      // this.$loader.start();
      // this.$axios
      //   .post("/planners/event-tickets-list", {
      //     event: this.eventUrl,
      //     date: dateID,
      //     orderCounter: this.orderCounter,
      //     isComps: true,
      //     isPrint: true
      //   })
      //   .then((response) => {
      //     this.orderCounter = response.data.orderCounter;
      //     this.eventdata = response.data.event;
      //     this.eventdata.start = dateFormat(
      //       +this.eventdata.start.$date.$numberLong,
      //       "ddd, mmm dS, yyyy"
      //     );
      //     const tickets = response.data.tickets.map((t) => {
      //       if (t.date && t.date.$date) {
      //         t.date = dateFormat(
      //           +t.date.$date.$numberLong,
      //           "ddd, mmm dS, yyyy"
      //         );
      //       }
      //       t.ticket_date = "";
      //       t.ticket_time = "";
      //       if (t.tier_date && t.tier_date.$date) {
      //         if (t.tier_type === "Date" || t.tier_type === "Date and Time") {
      //           t.ticket_date = dayjs(+t.tier_date.$date.$numberLong).format(
      //             "MMM DD, YYYY"
      //           );
      //         }
      //         if (t.tier_type === "Date and Time") {
      //           t.ticket_time = dayjs(+t.tier_date.$date.$numberLong).format(
      //             "h:mm A"
      //           );
      //         }
      //       }
      //       //create empty row in csv
      //       t.empty = " ";
      //       //end
      //       return t;
      //     });
      //     const products = response.data.products.map((p) => {
      //       if (p.order_date.$date) {
      //         p.order_date = dateFormat(
      //           +p.order_date.$date.$numberLong,
      //           "ddd, mmm dS, yyyy"
      //         );
      //       }
      //       return p;
      //     });
      //     const hasNoTierDates = tickets.every((t) => !t.ticket_date);
      //     const hasNoTierTimes = tickets.every((t) => !t.ticket_time);
      //     this.tableData = {
      //       title: "Event Tickets List Quick View",
      //       columns: [
      //         { text: "Ticket #", value: "number", width: "110px" },
      //         { text: "Refunded", value: "refunded", align: " d-none" },
      //         { text: "", value: "empty", align: " d-none" },
      //         { text: "Buyer Name", value: "purchaser", width: "auto" },
      //         { text: "Buyer Email", value: "email", width: "150px" },
      //         { text: "Buyer Phone", value: "phone", align: " d-none" },
      //         {
      //           text: "Buyer IP City",
      //           value: "buyer_ip_city",
      //           align: " d-none"
      //         },
      //         {
      //           text: "Buyer IP Address",
      //           value: "buyer_ip_address",
      //           align: " d-none"
      //         },
      //         {
      //           text: "Buyer IP Country",
      //           value: "buyer_ip_country",
      //           align: " d-none"
      //         },
      //         { text: "Group Ages", value: "age", align: " d-none" },
      //         { text: "", value: "empty", align: " d-none" },
      //         {
      //           text: "Attendee Name",
      //           value: "attendee_name",
      //           width: "160px",
      //           align: " d-none"
      //         },
      //         {
      //           text: "Attendee Email",
      //           value: "attendee_email",
      //           align: " d-none"
      //         },
      //         {
      //           text: "Attendee Phone",
      //           value: "attendee_phone",
      //           align: " d-none"
      //         },
      //         {
      //           text: "Attendee IP City",
      //           value: "attendee_ip_city",
      //           align: " d-none"
      //         },
      //         {
      //           text: "Attendee IP Address",
      //           value: "attendee_ip_address",
      //           align: " d-none"
      //         },
      //         {
      //           text: "Attendee IP Country",
      //           value: "attendee_ip_country",
      //           align: " d-none"
      //         },
      //         { text: "", value: "empty", align: " d-none" },
      //         {
      //           text: "Ticket $ (-Fees)",
      //           value: "revenue",
      //           width: "110px",
      //           isCurrency: true
      //         },
      //         { text: "Sold By", value: "sold_by", align: " d-none" },
      //         { text: "Purchase Date", value: "date", align: " d-none" },
      //         { text: "Cash", value: "cash", align: " d-none" },
      //         { text: "Comp", value: "comp", align: " d-none" },
      //         { text: "Promo Code", value: "promo", align: " d-none" },
      //         { text: "", value: "empty", align: " d-none" },
      //         {
      //           text: "Products",
      //           value: "has_products",
      //           width: "100px",
      //           bool: true
      //         },
      //         {
      //           text: "Notes",
      //           value: "notes",
      //           width: "100px",
      //           modal: true,
      //           modalTitle: "View/Add"
      //         },
      //         {
      //           text: "Details",
      //           value: "details",
      //           width: "110px",
      //           modalTwo: true,
      //           modalTwoTitle: "View Info",
      //           exempted: true
      //         }
      //       ],
      //       searchable: ["number", "purchaser", "attendee_name"],
      //       data: tickets
      //     };
      //     this.productTableData = {
      //       title: "Product Buyers List",
      //       columns: [
      //         { title: "Product", field: "name", width: "20%" },
      //         { title: "Quantity", field: "quantity", width: "6%" },
      //         {
      //           title: "Price",
      //           field: "price",
      //           isCurrency: true,
      //           hidden: true
      //         },
      //         { title: "Purchaser", field: "purchaser", width: "15%" },
      //         { title: "Purchase Date", field: "order_date", width: "15%" },
      //         { title: "City", field: "city", hidden: true },
      //         { title: "State", field: "state", hidden: true },
      //         { title: "Country", field: "country", hidden: true },
      //         { title: "Postal Code", field: "postal_code", hidden: true },
      //         { title: "Address", field: "address", hidden: true },
      //         { title: "Suite", field: "suite", hidden: true },
      //         { title: "Age", field: "age", hidden: true },
      //         { title: "Email", field: "email", width: "21%" },
      //         { title: "Phone Number", field: "phone", width: "10%" },
      //         { title: "Cash", field: "cash", hidden: true },
      //         { title: "Promo Code", field: "promo", hidden: true },
      //         { title: "Sold By", field: "sold_by", hidden: true },
      //         {
      //           title: "Notes",
      //           field: "notes",
      //           modal: true,
      //           modalTitle: "View/Add",
      //           width: "6%"
      //         }
      //       ],
      //       searchable: ["name", "purchaser", "email", "phone"],
      //       data: products
      //     };
      //     this.$loader.stop();
      //   });
    },
    validateOrderForm() {
      this.$store.state.loading = true;
      this.$axios
        .post("/order/create-manual", {
          ...this.orderForm,
          eventId: this.event._id
        })
        .then(response => {
          this.$store.state.loading = false;
          if (response.data.error) {
            this.$toast(response.data.error, { type: "error" });
          } else {
            this.$toast(response.data.message, { type: "success" });
            window.location.reload();
          }
        });
    },
    formatTierDate(tier) {
      const start = dayjs(+tier.sale_start.$date.$numberLong);
      const end = dayjs(+tier.sale_end.$date.$numberLong);

      return start.format("llll") + " - " + end.format("llll");
    }
  },
  computed: {
    orderAmount() {
      let amount = 0;
      this.orderForm.tiers.forEach(tier => {
        amount += tier.price * tier.quantity;
        if (this.event.buyerOrPlannerPaysFees == "buyerPaysFees") {
          amount += tier.dfee * tier.quantity;
        }
      });
      this.orderForm.products.forEach(product => {
        amount += product.price * product.quantity;
        if (this.event.buyerOrPlannerPaysFees == "buyerPaysFees") {
          amount += product.dfee * product.quantity;
        }
      });
      return amount;
    },
    checkTable: {
      get: function() {
        return this.tableLength;
      },
      set: function(val) {
        this.tableLength = val;
      }
    }
  },
  async mounted() {
    this.intervalStop = setInterval(this.fetchData, 8000);
    await this.$axios
      .get(`/events/edit/${this.$route.params.url}`)
      .then(response => {
        this.event = response.data;
      });

    this.$store.commit("setTitle", this.event.name);
    this.$store.commit("setSubtitle", "Ticket Lists");

    this.isRecurringEvent = this.event.frequency != "SINGLE_DATE";
    this.isReservedSeats =
      this.event && this.event.eventType == "RESERVED_SEATS";
    if (this.isReservedSeats) {
      this.tableContent.columns.push({ text: "Table Seats", value: "seats" });
    }
    if (this.event.companyUrl == "nmp") {
      this.tableContent.columns.push({
        text: "Resend Att Reminder",
        value: "resendAttReminder"
      });
    }
    for (const tier of this.event.tiers) {
      tier.saleDates.forEach(t => {
        let date = this.formatTierDate(t);

        this.tiersData.push({
          ...tier,
          saleDateId: t.id,
          date: date,
          availableQuantity: t.qty
        });
        this.tierList.push({
          value: t.id,
          label: `${tier.name} - ${date} - $${tier.price.toFixed(2)}`
        });
      });
    }
    this.event.products.forEach(product => {
      let variants = product.variants;
      let p = product;

      variants.forEach(v => {
        this.productsData.push({
          ...p,
          variantId: v.id,
          variantName: v.name,
          price: v.price.$numberDecimal,
          ccfee: v.ccfee ? v.ccfee.$numberDecimal : "",
          tfee: v.tfee.$numberDecimal,
          availableQuantity: v.quantity
        });
        this.products.push({
          value: v.id,
          label: `${product.title} - ${v.name} - $${v.price.$numberDecimal}`
        });
      });
    });
    await this.$axios
      .get(`/planners/${this.$route.params.url}/get-promoters`)
      .then(response => {
        this.promoters = response.data;
      });

    this.$axios
      .post(`/planners/retrieve-ticket-fees`, {
        event: this.$route.params.url
      })
      .then(response => {
        if (response.status == 200) {
          this.TFeePriceRange = response.data;
        }
      });
  },
  beforeDestroy() {
    if (this.intervalStop !== null) {
      clearInterval(this.intervalStop);
    }
  }
};
</script>
