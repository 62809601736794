<template>
  <div>
    <FormulateForm
      ref="form"
      @submit="validateOrderForm"
      v-model="orderForm"
      name="manualOrderForm"
    >
      <CSVImporter
        v-model="uploadedList"
        :columns="columns"
        :rowLimit="1000000"
        :showBtn="false"
        ref="importer"
      />
      <div class="row" style="margin-top: 10px">
        <div class="col half">
          <FormulateInput
            label="Location"
            name="location"
            type="text"
            placeholder="Country of Residence"
          />
        </div>
        <div class="col half">
          <FormulateInput
            type="selectSearch"
            label="Promoter"
            currency="true"
            :options="promoters"
            name="promoter"
            placeholder="Please Select promoter"
            autocomplete="false"
          />
        </div>
        <div class="col half">
          <FormulateInput
            label="Company"
            name="company"
            type="text"
            placeholder="Company"
          />
        </div>
        <div class="col half">
          <FormulateInput
            label="Language"
            name="language"
            type="text"
            placeholder="Language"
          />
        </div>
        <div class="col half">
          <FormulateInput
            label="Order Date"
            name="orderDate"
            type="dateTime"
            placeholder="Select Date"
          />
        </div>
      </div>

      <div class="row">
        <FormulateInput
          type="group"
          name="tiers"
          label="Tiers"
          :repeatable="true"
          add-label="+ Add Tiers"
          :value="orderForm.tiers"
          #default="{ index }"
          autocomplete="false"
        >
          <div class="col quarterx3 ticket-tier-container">
            <TicketTierSelector
              v-if="isRecurringEvent"
              :error="false"
              :tiers="tiersData"
              v-model="tierholders[index]"
              @input="addRecurringTier($event, index)"
            />
            <FormulateInput
              v-else
              type="select"
              label="Ticket tier"
              :options="tierList"
              name="saleDateId"
              placeholder="Please Select"
              :bundle="true"
              @input="addOrderTier($event, index)"
              autocomplete="false"
            />
          </div>
          <div class="col quarter ticket-tier-quantity">
            <FormulateInput
              type="number"
              label="Quantity"
              name="quantity"
              autocomplete="false"
              @input="tierQty($event, index)"
            />
          </div>
        </FormulateInput>
      </div>
      <div class="row">
        <FormulateInput
          type="group"
          name="products"
          :repeatable="true"
          label="Products"
          add-label="+ Add Products"
          :value="orderForm.products"
          #default="{ index }"
          autocomplete="false"
        >
          <div class="col quarterx3 ticket-tier-container">
            <FormulateInput
              type="select"
              label="Product"
              :options="products"
              name="variantId"
              placeholder="Please Select"
              :bundle="true"
              @input="addProducts($event, index)"
              autocomplete="false"
            />

            <!-- <span class="ticket-tier-plus-sign">+</span> -->
          </div>
          <div class="col quarter ticket-tier-quantity">
            <FormulateInput
              type="number"
              label="Quantity"
              name="quantity"
              autocomplete="false"
            />
          </div>
          <!-- <FormulateInput
            type="money"
            label="Discount"
            :placeholder="methodTypePlaceholderDisplay"
            validation="required"
            name="methodValue"
          /> -->
        </FormulateInput>
      </div>
      <div class="amount">
        <span>Total: {{ orderAmount }}</span>
      </div>
      <!--        useEmails-->
      <FormulateInput
        type="checkbox"
        name="useEmails"
        label="Send notification emails to user"
        autocomplete="false"
      />
      <FormulateInput
        class="form-submit"
        name="Add Order"
        type="submit"
      ></FormulateInput>
    </FormulateForm>
  </div>
</template>
<script>

import TicketTierSelector from "@/components/TicketTierSelector.vue";
import CSVImporter from "@/components/CSVImporter.vue";

export default {
  components: {
    TicketTierSelector,
    CSVImporter
  },
  props: {
    promoters: {
      type: Array,
      default: []
    },
    isRecurringEvent: {
      type: Boolean,
      default: false
    },
    tiersData: {
      type: Array,
      default: []
    },
    event: {
      type: Array,
      default: []
    },
    tierList: {
      type: Array,
      default: []
    },
    products: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      tierholders: [],
      uploadedList: [],
      orderForm: {
        location: "",
        language: "",
        company: "",
        tiers: [],
        products: [],
        promoter: "",
        useEmails: false
      },
      columns: [
        {
          name: "first_name",
          property: "first_name",
          required: true,
          example: "John",
        },
        {
          name: "last_name",
          property: "last_name",
          required: true,
          example: "Doe",
        },
        {
          name: "email",
          required: true,
          property: "email",
          example: "example@example.com",
        },
        {name: "phone", property: "phone", example: "12041233442"},
      ],
    }
  },
  methods: {
    validateOrderForm() {
      if(!this.$refs.importer || !this.$refs.importer.contacts) {
        this.$toast("You are not imported list of buyers", {type: "error"});
        return;
      }
      if(!this.orderForm.tiers.length > 1 || !this.orderForm.products.length > 1) {
        this.$toast("You are not select tiers or products", {type: "error"});
        return;
      }

      this.$store.state.loading = true;
      this.$axios
        .post("/order/create-manual-bulk", {
          ...this.orderForm,
          userList: this.$refs.importer.contacts,
          eventId: this.event._id
        })
        .then(response => {
          this.$store.state.loading = false;
          if (response.data.error) {
            this.$toast(response.data.error, {type: "error"});
          } else {
            this.$toast(response.data.message, {type: "success"});
            window.location.reload();
          }
        });
    },
    addRecurringTier(data, index) {
      let ogTier = this.tiersData.find(t => t.saleDateId === data);
      ogTier.type = data.type;
      ogTier.tierId = ogTier.id;
      ogTier.venueAccessDateID = data.venueAccessDateID;
      ogTier.venueAccessTimeID = data.venueAccessTimeID;
      ogTier.quantity = 1;
      ogTier.dFee = 0;

      if (this.event.companyUrl != "nmp") {
        if (this.event.buyerOrPlannerPaysFees == "buyerPaysFees") {
          this.TFeePriceRange.forEach(fee => {
            if (
              ogTier.price >= fee.startAmount &&
              ogTier.price <= fee.endAmount
            ) {
              if (
                ogTier.ticketType == "IN_PERSON" ||
                ogTier.ticketType == "IN_PERSON_RESERVED"
              ) {
                ogTier.dFee = fee.InPerson;
              } else if (ogTier.ticketType == "VIRTUAL") {
                ogTier.dFee = fee.Virtual;
              } else if (ogTier.ticketType == "DONATION") {
                ogTier.dFee = fee.Donations;
              }
            }
          });
        }
      }
      this.orderForm.tiers[index] = ogTier;
    },
    addOrderTier(data, index) {
      let ogTier = this.tiersData.find(t => t.saleDateId === data);
      // ogTier.type = data.type;
      ogTier.tierId = ogTier.id;
      ogTier.venueAccessDateID = data.venueAccessDateID;
      ogTier.venueAccessTimeID = data.venueAccessTimeID;
      ogTier.quantity = 1;
      ogTier.dfee = 0;

      if (this.event.companyUrl != "nmp") {
        if (this.event.buyerOrPlannerPaysFees == "buyerPaysFees") {
          this.TFeePriceRange.forEach(fee => {
            if (
              ogTier.price >= fee.startAmount &&
              ogTier.price <= fee.endAmount
            ) {
              if (
                ogTier.ticketType == "IN_PERSON" ||
                ogTier.ticketType == "IN_PERSON_RESERVED"
              ) {
                ogTier.dfee = fee.InPerson;
              } else if (ogTier.ticketType == "VIRTUAL") {
                ogTier.dfee = fee.Virtual;
              } else if (ogTier.ticketType == "DONATION") {
                ogTier.dfee = fee.Donations;
              }
            }
          });
        }
      }
      this.orderForm.tiers[index] = ogTier;
    },
    tierQty(qty, index) {
      if (
        this.orderForm.tiers[index].saleDates &&
        qty >
        this.orderForm.tiers[index].saleDates[0].qty -
        this.orderForm.tiers[index].saleDates[0].start
      ) {
        this.orderForm.tiers[index].quantity =
          this.orderForm.tiers[index].saleDates[0].qty -
          this.orderForm.tiers[index].saleDates[0].start;
      } else {
        this.orderForm.tiers[index].quantity = qty;
      }
    },
    addProducts(data, index) {
      let ogProduct;
      // TODO Do duplicate validation
      ogProduct = this.productsData.find(product => product.variantId === data);
      ogProduct.productId = ogProduct.product_id.$oid;
      ogProduct.bundlePrice = parseInt(ogProduct.price);
      ogProduct.quantity = 1;
      ogProduct.dFee = 0;
      if (this.event.buyerOrPlannerPaysFees == "buyerPaysFees") {
        this.TFeePriceRange.forEach(fee => {
          if (
            ogProduct.price >= fee.startAmount &&
            ogProduct.price <= fee.endAmount
          ) {
            ogProduct.dFee = fee.Products;
          }
        });
      }
      this.orderForm.products[index] = ogProduct;
    },
  },
  computed: {
    orderAmount() {
      let amount = 0;
      this.orderForm.tiers.forEach(tier => {
        amount += tier.price * tier.quantity;
        if (this.event.buyerOrPlannerPaysFees == "buyerPaysFees") {
          amount += tier.dfee * tier.quantity;
        }
      });
      this.orderForm.products.forEach(product => {
        amount += product.price * product.quantity;
        if (this.event.buyerOrPlannerPaysFees == "buyerPaysFees") {
          amount += product.dfee * product.quantity;
        }
      });
      return amount;
    }
  }
}
</script>
<style scoped lang="less">

</style>