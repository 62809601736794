<template>
  <div class="table-wrap">
    <v-app>
      <v-card class="mb-16">
        <v-card-title>
          {{ title }}
          <v-divider
            v-if="!isFinancials && !currentLetter"
            class="mx-4"
            inset
            vertical
          ></v-divider>

          <v-text-field
            v-if="!isFinancials && !currentLetter"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            class="mr-2"
            v-if="create"
            @click="$emit('create')"
          >
            Create
          </v-btn>

          <JsonCSV :data="jsonData" :name="title.toLowerCase() + '.csv'">
            <v-btn color="primary"> Download Detailed CSV </v-btn>
          </JsonCSV>
        </v-card-title>
        <!-- <v-tabs v-if="tabs">
          <v-tab
            v-for="tab in tabs"
            :key="tab.link"
            @click="onClickTab(tab.link)"
          >
            {{ tab.text }}
          </v-tab>
        </v-tabs> -->
        <v-col cols="12" mx="6" class="py-2" v-if="alphabetSearch">
          <p>Exclusive</p>

          <v-btn-toggle v-model="toggle_exclusive" dense @change="letterSearch">
            <v-btn v-for="alphabet in alphabets" :key="alphabet">
              {{ alphabet }}
            </v-btn>
          </v-btn-toggle>
        </v-col>

        <v-data-table
          dense
          :headers="columns"
          :items="data"
          :loading="loading"
          :search="search"
          :page="loadLastPage"
          :server-items-length="dataCount"
          @update:page="onPagination"
          @update:items-per-page="onPageSizeChange"
          class="elevation-1"
          :footer-props="footerProps"
        >
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="600px">
              <v-card>
                <v-tabs :show-arrows="true" v-model="tab">
                  <v-tab v-for="tab in tabs" :key="tab" :href="`#tab-${tab}`">
                    {{ tab }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item value="tab-ORDER DETAILS">
                    <v-card-title>
                      <span class="text-h5">Order Details</span>
                    </v-card-title>

                    <v-card-text>
                      <v-row>
                        <!-- {{ detailsItem }} -->
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="Name"
                            outlined
                            dense
                            disabled
                            :value="detailsItem.name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="Email"
                            outlined
                            dense
                            disabled
                            :value="detailsItem.email"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="Tickets Count"
                            outlined
                            dense
                            disabled
                            :value="detailsItem.ticket_count"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="Products Count"
                            outlined
                            dense
                            disabled
                            :value="detailsItem.product_count"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="Invoice"
                            outlined
                            dense
                            disabled
                            :value="detailsItem.invoice"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="IP Address"
                            outlined
                            dense
                            disabled
                            :value="
                              detailsItem.buyer_ip_information
                                ? detailsItem.buyer_ip_information.ip
                                : 'N/A'
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="City"
                            outlined
                            dense
                            disabled
                            :value="detailsItem.city"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="Total Revenue"
                            outlined
                            dense
                            disabled
                            :value="detailsItem.ticket_subtotal"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text> </v-btn>
                      <!-- <v-btn color="blue darken-1" text> Save </v-btn>  -->
                    </v-card-actions>
                  </v-tab-item>
                  <v-tab-item value="tab-TICKET DETAILS">
                    <v-card-title>
                      <span class="text-h5">Ticket Details</span>
                    </v-card-title>

                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">#</th>
                            <th class="text-left">Validation</th>
                            <th class="text-left">Tier Name</th>
                            <th class="text-left">Attendee Name</th>
                            <th class="text-left">Attendee Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="ticket in detailsItem.tickets"
                            :key="ticket.number"
                          >
                            <td>{{ ticket.number }}</td>
                            <td>{{ ticket.validation }}</td>
                            <td>{{ ticket.tier }}</td>

                            <td>
                              {{
                                hasValueNested(ticket, "attendee.name")
                                  ? ticket.attendee.name
                                  : "No attendee"
                              }}
                            </td>
                            <td>
                              {{
                                hasValueNested(ticket, "attendee.email")
                                  ? ticket.attendee.email
                                  : "No attendee"
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <!-- <v-container>
                        <v-row
                          v-for="ticket in detailsItem.tickets"
                          :key="ticket.indexId"
                        >
                          <v-col cols="12" sm="6">
                            {{ ticket.validation }}
                          </v-col>
                          <v-col cols="12" sm="6"> {{ ticket.number }} </v-col>
                        </v-row>
                      </v-container> -->

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text> </v-btn>
                      <!-- <v-btn color="blue darken-1" text> Save </v-btn>  -->
                    </v-card-actions>
                  </v-tab-item>
                  <v-tab-item value="tab-ADDITIONAL DETAILS">
                    <v-card-title>
                      <span class="text-h5">ADDITIONAL Details</span>
                    </v-card-title>

                    <v-card-text>
                      <v-row>
                        <!-- {{ detailsItem }} -->
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="Address"
                            outlined
                            dense
                            disabled
                            :value="detailsItem.address1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="Phone"
                            outlined
                            dense
                            disabled
                            :value="detailsItem.phone"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="Age"
                            outlined
                            dense
                            disabled
                            :value="detailsItem.age"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="Payment Method"
                            outlined
                            dense
                            disabled
                            :value="detailsItem.payment_method"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text> </v-btn>
                      <!-- <v-btn color="blue darken-1" text> Save </v-btn>  -->
                    </v-card-actions>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.notes`]="{ item }">
            <v-icon @click="$emit('notesModal', item)"> mdi-pencil </v-icon>
          </template>
          <template v-slot:[`item.details`]="{ item }">
            <v-icon @click="details(item)"> mdi-information-outline </v-icon>
          </template>
          <template v-slot:item.price="{ item }">
            <span v-if="getColumnFormat('price') === 'currency'">$</span>{{ item.price }}
          </template>
        </v-data-table>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import Axios from "axios";
// import { debounce } from "debounce";
import jsPDF from "jspdf";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faFilePdf,
  faLock,
  faLockOpen,
  faCheckSquare,
  faInfoCircle,
  faChevronLeft,
  faChevronRight,
  faCog,
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import JsonCSV from "vue-json-csv";

library.add(faFilePdf);
library.add(faLock);
library.add(faLockOpen);
library.add(faCheckSquare);
library.add(faInfoCircle);
library.add(faRedoAlt);
library.add(faCog);
library.add(faChevronLeft);
library.add(faChevronRight);

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
export default {
  name: "base-table",
  components: {
    FontAwesomeIcon,
    JsonCSV,
  },
  props: {
    tabs: { default: () => [], type: Array },
    alphabetSearch: {
      type: Boolean,
      default: false,
    },
    create: {
      type: Boolean,
      default: false,
    },
    func: Function,
    endpoint: String,
    params: {
      default: () => {
        return {};
      },
      type: Object,
    },
    lastPage: { type: Number, default: 1 },
    title: { type: String, default: "" },
    approveBtn: Boolean,
    columns: {
      type: Array,
      default: () => [],
      description: "Table columns",
    },
    tableType: {
      type: String,
      default: "",
    },
    type: {
      type: String, // striped | hover
      default: "",
      description: "Whether table is striped or hover type",
    },
    theadClasses: {
      type: String,
      default: "",
      description: "<thead> css classes",
    },
    tbodyClasses: {
      type: String,
      default: "",
      description: "<tbody> css classes",
    },
    url: String,
    isFinancials: Boolean,
  },
  data() {
    return {
      toggle_exclusive: 0,
      alphabets: [
        "all",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ],
      interests: [],
      page: 1,
      data: [],
      jsonData: [],
      dataCount: 0,
      loading: true,
      pageSize: 10,
      checked: false,
      search: "",
      currentLetter: null,
      footerProps: {
        "items-per-page-options": [10, 25, 50, 100],
        "show-current-page": true,
        "show-first-last-page": true,
      },
      tab: null,

      dialog: false,
      detailsIndex: -1,
      detailsItem: {},
      // loading: true,
    };
  },
  watch: {
    search() {
      this.loading = true;
      this.refetchData();
    },
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
    loadLastPage() {
      if (this.lastPage > 1) {
        this.page = this.lastPage;
        return this.lastPage;
      } else {
        return 1;
      }
    },
    pageButtons() {
      if (this.pages <= 4) {
        return [1, 2, 3, 4].slice(0, this.pages);
      }

      if (this.page > this.pages - 5) {
        return [
          this.pages - 4,
          this.pages - 3,
          this.pages - 2,
          this.pages - 1,
          this.pages,
        ];
      }

      return [this.page, this.page + 1, "...", this.pages - 1, this.pages];
    },
    pages() {
      if (!this.data) return 0;
      // return Math.ceil(this.data.length / this.tableSize.value);
      return Math.ceil(this.dataCount / this.tableSize);
    },
    query() {
      if (this.currentLetter) {
        return {
          limit: this.pageSize,
          skip: (this.page - 1) * this.pageSize,
          search: this.search,
          letterSearch: this.currentLetter,
        };
      } else {
        return {
          limit: this.pageSize,
          skip: (this.page - 1) * this.pageSize,
          search: this.search,
        };
      }
    },
  },
  async created() {
    this.fetchOnLoad();
  },
  mounted() {},
  methods: {
    getColumnFormat(columnValue) {
      const column = this.columns.find(col => col.value === columnValue);
      return column ? column.format : null;
    },
    letterSearch(letter) {
      if (letter === 0) {
        this.currentLetter = null;
        this.loading = true;
        this.refetchData();
      } else {
        this.currentLetter = this.alphabets[letter];
        this.loading = true;
        this.refetchData();
      }
    },
    details(item) {
      this.detailsIndex = this.data.indexOf(item);
      this.detailsItem = Object.assign({}, item);
      this.dialog = true;
    },
    onClickTab(tab) {
      this.endpoint = tab;
      this.fetchOnLoad();
    },
    refetchData: debounce(function () {
      this.page = 1;
      this.fetchOnLoad();
    }, 400),

    async fetchData() {
      let newQuery = { ...this.params, ...this.query };
      if (Object.keys(this.params).length !== 0) {
        const { data } = await this.$axios.post(this.endpoint, newQuery);
        return data;
      } else {
        const { data } = await this.$axios.post(this.endpoint, this.query);
        return data;
      }
    },
    async fetchOnLoad() {
      const data = await this.fetchData();

      this.data = data.items;
      this.dataCount = data.count;
      this.loading = false;

      this.updateTable(0, this.pageSize, data.items);
      this.getJsonData(data.items);
    },
    getJsonData(data) {
      // Unique Json For Buyer's List
      if (this.tableType === "buyerlist") {
        let newOrders = {};
        let ticketList = [];
        data.forEach((order) => {
          if (order.tickets) {
            order.tickets.forEach((ticket) => {
              ticketList.push({
                "Ticket Number": ticket.number,
                Validation: ticket.validation,
                Tier: ticket.tier,
                "Ticket Type": ticket.ticketType,
                Name: order.name,
                Email: order.account_email || order.email,
                Phone: order.phone,
                City: order.city,

                Age: order.age,
                "Order Date": order.order_date,
                "Ip Address": order.buyer_ip_information
                  ? order.buyer_ip_information.ip
                  : "N/A",
                "IP Country": order.buyer_ip_information
                  ? order.buyer_ip_information.ip
                  : "",
                "IP City": order.buyer_ip_information
                  ? order.buyer_ip_information.ip
                  : "",
                "Attendee Name": ticket.attendee ? ticket.attendee.name : "N/A",
                "Attendee Email": ticket.attendee ? ticket.attendee.email : "",
                "Attendee Phone": ticket.attendee ? ticket.attendee.phone : "",
                "Attendee Ip Address": this.hasValueNested(
                  order,
                  "attendee.attendee_ip_information.ip"
                )
                  ? order.attendee.attendee_ip_information.ip
                  : "",
                "Attendee Ip Country": this.hasValueNested(
                  order,
                  "attendee.attendee_ip_information.country_name"
                )
                  ? order.attendee.attendee_ip_information.country_name
                  : "",
                "Attendee Ip City": this.hasValueNested(
                  order,
                  "attendee.attendee_ip_information.city"
                )
                  ? order.attendee.attendee_ip_information.city
                  : "",
              });
            });
          }
        });

        this.jsonData = ticketList;
      } else {
        this.jsonData = data;
      }
    },
    onPageSizeChange(pagesize) {
      //
      this.loading = true;
      this.pageSize = pagesize;
      this.fetchOnLoad();
      this.page = 1;
    },
    onPagination(page) {
      this.loading = true;
      this.page = page;
      this.fetchOnLoad();
      // this.$emit("paginate", {
      //   page: page,
      // });
    },
    updateTable(start, end, arrayToLoop) {
      let arrayToDisplay = [];
      for (let x = start; x <= end - 1; x++) {
        if (arrayToLoop[x] != null) {
          arrayToDisplay.push(arrayToLoop[x]);
        }
      }
      this.data = arrayToDisplay;
    },

    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    hasValueNested(obj, key) {
      return key.split(".").every(function (x) {
        if (typeof obj != "object" || obj === null || !x in obj) return false;
        obj = obj[x];
        return true;
      });
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    closeModal(modalId, action, userId) {
      document.getElementById(modalId).style.display = "none";
      if (action == "lock") {
        // api call to lock user here
        Axios.post(`${process.env.VUE_APP_SERVICE_URL}lock-user`, {
          userId: userId,
        }).then((response) => {
          this.data.forEach((ele) => {
            if (ele.id == userId) {
              ele.accountLock = true;
            }
          });
        });
      } else if (action == "unlock") {
        // api call to unlock user here
        Axios.post(`${process.env.VUE_APP_SERVICE_URL}unlock-user`, {
          userId: userId,
        }).then((response) => {
          this.data.forEach((ele) => {
            if (ele.id == userId) {
              ele.accountLock = false;
            }
          });
        });
      } else if (action == "verify") {
        Axios.post(`${process.env.VUE_APP_SERVICE_URL}verify-user`, {
          userId: userId,
        }).then((response) => {
          let index;
          for (let x = 0; x < this.data.length; x++) {
            if (this.data[x].id == userId) {
              index = x;
            }
          }
          this.data.splice(index, 1);
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
* {
  box-sizing: border-box !important;
}
.table-wrap {
  width: 100%;
}
.login-btn {
  cursor: pointer;
}
.approve-btn,
.blacklist-btn {
  color: #ffffff;
  border: 0px;
  border-radius: 5px;
}
.approve-btn {
  background-color: green;
}
.blacklist-btn {
  background-color: rgb(163, 24, 24);
}
td > img {
  max-width: 30%;
  border-radius: 0.2857rem;
}
td > a {
  cursor: pointer;
}
.textarea {
  border-radius: 13px;
}

.modal-mask {
  display: none;
  justify-content: center;
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  transition: opacity 0.3s ease;
}

div.modal-container {
  margin: auto;
  top: 300px;
  padding: 20px 30px;
  background-color: #fff;
  /* background-color: #222a3f; */
  color: black;
  /* color: white; */
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  position: relative;
  border: 5px solid #41b883;
}
div.info-modal-container {
  margin: auto;
  top: 100px;
  padding: 20px 30px;
  background-color: #fff;
  color: black;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  position: relative;
  border: 5px solid #41b883;
}
div.modal-header h3 {
  margin-top: 0;
  color: #41b883;
}
div.modal-container h5 {
  margin-top: 0;
  color: black;
}
div.modal-container h4 {
  margin-top: 0;
  color: #41b883;
}
.modal-wrapper {
  position: relative;
  left: 1em;
  top: -5em;
}
.buttons {
  display: flex;
  justify-content: flex-end;
}
button.pop-up-btn {
  margin-left: 11px;
  border: none;
  font-size: 25px;
  color: #41b883;
  background: transparent;
  /* border-radius: 5px; */
  cursor: pointer;
  /* background-color: #e14eca; */
  padding: 8px;
  /* border: 2px solid #41B883; */
}
.modal-body {
  margin: 20px 0;
}
.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.btn-close {
  border: none;
  font-size: 28px;
  cursor: pointer;
  color: #41b883;
}
.pop-header {
  display: flex;
}
.table-responsive {
  // overflow: scroll;
  padding: 1px 26px 0px 20px;
  // overflow: auto;
  display: flex;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.action {
  cursor: pointer;
}

.pagination {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  user-select: none;

  p {
    padding: 7px 14px;
    border: rgba(91, 91, 91, 0.3) solid 1px;
    // color: #4eb95e;

    &:hover {
      cursor: pointer;
      background-color: rgba(91, 91, 91, 0.3);
    }

    &.active {
      background-color: rgba(91, 91, 91, 0.4);
    }

    &.inactive {
      color: #999999;
      pointer-events: none;
    }

    &.from {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &.to {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}
</style>
<style lang="scss">
.v-application .primary {
  background-color: var(--primary-green) !important;
  border-color: var(--primary-green) !important;
}
.theme--dark.v-tabs > .v-tabs-bar {
  background: var(--content-light) !important;
}
.v-application--wrap {
  min-height: fit-content !important;
}
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #2a2d3f !important;
}
.v-sheet.v-card,
.theme--dark.v-data-table {
  background: var(--content-light) !important;
}

.v-item-group {
  overflow: auto;
}
.theme--dark.v-btn-toggle:not(.v-btn-toggle--group) {
  background: initial;
}
</style>
