<template>
  <TTDialog :active="active" v-on:close="close" :width="650">
    <RefundBuyerList ref="refundModal" @issueRefund="issueRefund" />
    <v-app>
      <v-card>
        <v-tabs :show-arrows="true" v-model="tab" color="var(--primary)">
          <v-tab v-for="tab in tabs" :key="tab" :href="`#tab-${tab}`">
            {{ tab }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-ORDER DETAILS">
            <v-card-title>
              <span class="text-h5">Order Details - {{ order._id.$oid }}</span>
            </v-card-title>

            <v-card-text v-if="!edits">
              <div class="row">
                <div class="detail col half">
                  <strong>Name</strong>

                  <span>{{ order.name }}</span>
                </div>
                <div class="detail col half">
                  <strong>Email</strong>

                  <span>{{ order.account_email }}</span>
                </div>
              </div>
              <div class="row">
                <div class="detail col half">
                  <strong>Ticket Count</strong>

                  <span>{{ order.ticket_count }}</span>
                </div>
                <div class="detail col half">
                  <strong>Product Count</strong>

                  <span>{{ order.product_count }}</span>
                </div>
              </div>
              <div class="row">
                <div class="detail col half">
                  <strong>Invoice</strong>

                  <span>{{ order.invoice }}</span>
                </div>
                <div class="detail col half">
                  <strong>IP Address</strong>

                  <span>
                    {{
                      order.buyer_ip_information
                        ? order.buyer_ip_information.ip
                        : "N/A"
                    }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="detail col half">
                  <strong>City</strong>

                  <span>{{ order.city }}</span>
                </div>
                <div class="detail col half">
                  <strong>Subtotal</strong>

                  <span>${{ order.ticket_subtotal }}</span>
                </div>
              </div>
              <div class="row" v-if="order.upgraded">
                <div class="detail col half">
                  <strong>Upgraded</strong>

                  <span>{{ order.upgraded }}</span>
                </div>
              </div>
              <div class="row" v-if="order.refundAmount">
                <div class="detail col half">
                  <strong>Refund Amount</strong>
                  <span>${{ order.refundAmount }}</span>
                </div>
              </div>
            </v-card-text>
            <v-card-text v-if="edits">
              <div class="row">
                <div class="detail col half">
                  <formulate-input
                    label="First Name"
                    type="text"
                    v-model="order.first_name"
                  ></formulate-input>
                </div>
                <div class="detail col half">
                  <formulate-input
                    label="Last Name"
                    type="text"
                    v-model="order.last_name"
                  ></formulate-input>
                </div>
              </div>
              <div class="row">
                <div class="detail col half">
                  <formulate-input
                    label="Email"
                    type="text"
                    v-model="order.account_email"
                  ></formulate-input>
                </div>
                <div class="detail col half">
                  <formulate-input
                    label="Phone"
                    type="text"
                    v-model="order.phone"
                  ></formulate-input>
                </div>
              </div>
            </v-card-text>

            <v-card-text>
              <!-- <div class="row">
              <div class="detail col quarter">
                <v-btn
                  color="var(--primary)"
                  max-width="140px"
                
                >
                  <v-icon left>
                    mdi-pencil
                  </v-icon>
                  Edit Order
                </v-btn>
              </div>
            </div> -->
              <v-card-actions
                v-if="this.$store.state.user.activeBrand.subdomain == 'nmp'"
              >
                <v-spacer></v-spacer>
                <v-btn
                  color="var(--primary)"
                  @click="openRefundModal"
                  style="margin-right:16px"
                >
                  Refund
                </v-btn>
                <v-btn v-if="edits" color="var(--primary)" @click="saveInfo">
                  Save
                </v-btn>
                <v-btn v-else color="var(--primary)" @click="edits = true">
                  Edit Order
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-tab-item>
          <v-tab-item value="tab-TICKET DETAILS">
            <v-card-title>
              <span class="text-h5">Ticket Details</span>
            </v-card-title>

            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">#</th>
                    <th class="text-left">Waiver</th>
                    <th class="text-left">Attendee Name</th>
                    <th class="text-left">Attendee Email</th>
                    <th class="text-left">Tier Name</th>
                    <th class="text-left">Validation</th>
                    <th class="text-left">Upgraded</th>
                    <th class="text-left">Refunded</th>
                    <th class="text-left">Magic link</th>
                    <th class="text-left" v-if="isReservedSeatsType">Seats</th>
                  </tr>
                </thead>

                <tbody v-if="order.tickets.length">
                  <tr v-for="ticket in order.tickets" :key="ticket.number">
                    <td>{{ ticket.number }}</td>
                    <td style=" text-transform: capitalize;">
                      {{ ticket.waiver_signed ? ticket.waiver_signed : false }}
                    </td>
                    <td>
                      {{
                        hasValueNested(ticket, "attendee.name")
                          ? ticket.attendee.name
                          : "No attendee"
                      }}
                    </td>
                    <td>
                      {{
                        hasValueNested(ticket, "attendee.email")
                          ? ticket.attendee.email
                          : "No attendee"
                      }}
                    </td>
                    <td>{{ ticket.tierName }}</td>
                    <td>{{ ticket.validation }}</td>
                    <td>
                      {{
                        hasValueNested(ticket, "upgrade")
                          ? ticket.upgrade
                          : "False"
                      }}
                    </td>
                    <td>
                      {{
                        hasValueNested(ticket, "refunded") && ticket.refunded
                          ? "Yes"
                          : hasValueNested(ticket, "refundAmount") &&
                            ticket.refundAmount > 0
                          ? "Partial"
                          : "No"
                      }}
                    </td>
                    <td>{{ hasValueNested(ticket, "magic_link") ? ticket.magic_link : ""}}</td>
                    <td v-if="isReservedSeatsType">{{ ticket.seats }}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="6">All tickets was updated</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text></v-btn>
            </v-card-actions>
          </v-tab-item>
          <v-tab-item value="tab-Q&A">
            <v-card-title>
              <span class="text-h5">Q&A</span>
            </v-card-title>
            <v-card-text>
              <div
                v-for="(field, index) in order.custom_fields"
                class="row"
                :key="index"
              >
                <div class="detail col full">
                  <strong>{{ field.question }}</strong>
                  <span>{{ field.answers.join(", ") }}</span>
                </div>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text></v-btn>
            </v-card-actions>
          </v-tab-item>
          <v-tab-item value="tab-ADDITIONAL DETAILS">
            <v-card-title>
              <span class="text-h5">Additional Details</span>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Address"
                    outlined
                    disabled
                    :value="order.address1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" v-if="order.phone">
                  <v-text-field
                    label="Phone"
                    outlined
                    disabled
                    :value="order.phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" v-if="order.age">
                  <v-text-field
                    label="Age"
                    outlined
                    disabled
                    :value="order.age"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" v-if="order.payment_method">
                  <v-text-field
                    label="Payment Method"
                    outlined
                    disabled
                    :value="order.payment_method"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" v-if="order.discount_type">
                  <v-text-field
                    label="Order Type"
                    outlined
                    disabled
                    :value="order.discount_type"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" v-if="order.promoter_name">
                  <v-text-field
                    label="Promoter"
                    outlined
                    disabled
                    :value="`${order.promoter_name} (${order.promoter_email})`"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  v-if="
                    this.$store.state.user.activeBrand.subdomain == 'nmp' &&
                      order.firstEvent
                  "
                >
                  <v-text-field
                    label="First Purchase Event"
                    outlined
                    disabled
                    :value="order.firstEvent"
                  ></v-text-field>

                  <!-- <span></span>
                  <span></span> -->
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  v-if="
                    this.$store.state.user.activeBrand.subdomain == 'nmp' &&
                      order.firstOrderDate
                  "
                >
                  <v-text-field
                    label="First Purchase Date"
                    outlined
                    disabled
                    :value="order.firstOrderDate"
                  ></v-text-field>

                  <!-- <span></span>
                  <span></span> -->
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  v-if="this.$store.state.user.activeBrand.subdomain == 'nmp'"
                >
                  <v-combobox
                    label="Update Promoter"
                    outlined
                    :items="order.promoters"
                    :value="newPromoterValue"
                    @input="updatePromoterLocal"
                  ></v-combobox>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="saveButton"
                class="save-btn"
                color="var(--primary)"
                large
                @click="updatePromoterEvent"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-app>
  </TTDialog>
</template>

<script>
import TTDialog from "@/components/TTDialog.vue";
import RefundBuyerList from "./RefundBuyerList.vue";
export default {
  name: "order-details",
  components: {
    TTDialog,
    RefundBuyerList
  },
  props: {
    isReservedSeatsType: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      active: false,
      order: null,
      tab: 0,
      edits: false,
      refund: false,
      saveButton: false,
      newPromoter: "",
      newPromoterValue: ""
    };
  },
  computed: {
    saveBtnTxt() {
      if (this.edits) {
        return "Save";
      } else {
        return "Edit Order";
      }
    },
    tabs() {
      return [
        "ORDER DETAILS",
        "TICKET DETAILS",
        this.order && this.order.custom_fields && "Q&A",
        "ADDITIONAL DETAILS"
      ].filter(Boolean);
    }
  },
  methods: {
    open(order) {
      this.order = order;

      setTimeout(() => {
        this.active = true;
      }, 50);
    },
    close() {
      this.active = false;
    },
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    openRefundModal() {
      this.$refs.refundModal.open(this.order);
    },
    updatePromoterLocal(input) {
      this.newPromoterValue = input;
      this.newPromoter = input.value;
      this.saveButton = true;
    },
    updatePromoterEvent() {
      let newPromoter = this.newPromoter;
      this.newPromoter = "";
      this.newPromoterValue = "";
      this.$emit("update-promoter", newPromoter);
    },
    hasValueNested(obj, key) {
      return key.split(".").every(function(x) {
        if (typeof obj != "object" || obj === null || !x in obj) return false;
        obj = obj[x];
        return true;
      });
    },
    saveInfo() {
      this.$emit("editOrder", this.order);
      this.edits = false;
      this.close();
    },
    issueRefund() {
      this.$emit("issueRefund", this.order);
      this.refund = false;
      this.close();
      // alert("Refund");
    }
  }
};
</script>

<style lang="less" scoped>
.detail {
  display: flex;
  flex-direction: column;
}
</style>
