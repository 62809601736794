<template>
  <transition
    name="modal"
    v-on:before-enter="beforeEnter"
    v-on:enter="enter"
    v-on:leave="leave"
  >
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <font-awesome-icon
            icon="times"
            id="remove-pop-up"
            @click="$emit('close')"
          />
          <div class="modal-body" id="modal-scroll-container">
            <h3 class="modal-header-title modal-header-info">
              TICKET INFORMATION
            </h3>
            <div class="row">
              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">TICKET #:</div>
                <div class="buyer-info-value">
                  {{ ticket.number }}
                </div>
              </div>
              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">Validator:</div>
                <div class="buyer-info-value">
                  {{ ticket.validator }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">TICKET TIER:</div>
                <div class="buyer-info-value">
                  {{ ticket.tier }}
                </div>
              </div>
              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">REFUNDED:</div>
                <div class="buyer-info-value">
                  {{ ticket.refunded }}
                </div>
              </div>
            </div>

            <h3 class="modal-header-title modal-header-info">
              BUYER INFORMATION
            </h3>
            <div class="row">
              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">NAME:</div>
                <div class="buyer-info-value">
                  {{ ticket.purchaser }}
                </div>
              </div>
              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">EMAIL:</div>
                <div class="buyer-info-value">
                  {{ ticket.email }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">PHONE:</div>
                <div class="buyer-info-value">
                  {{ ticket.phone }}
                </div>
              </div>
              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">IP CITY:</div>
                <div class="buyer-info-value">
                  {{ ticket.buyer_ip_city }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">IP P/S:</div>
                <div class="buyer-info-value">
                  {{ ticket.buyer_ip_address }}
                </div>
              </div>
              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">IP COUNTRY:</div>
                <div class="buyer-info-value">
                  {{ ticket.buyer_ip_country }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">GROUP AGES:</div>
                <div class="buyer-info-value">
                  {{ ticket.age }}
                </div>
              </div>
            </div>

            <h3 class="modal-header-title modal-header-info">
              ATTENDEE INFORMATION
            </h3>
            <div class="row">
              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">NAME:</div>
                <div class="buyer-info-value">
                  {{ ticket.attendee_name }}
                </div>
              </div>
              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">EMAIL:</div>
                <div class="buyer-info-value">
                  {{ ticket.attendee_email }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">PHONE:</div>
                <div class="buyer-info-value">
                  {{ ticket.attendee_phone }}
                </div>
              </div>

              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">CITY:</div>
                <div class="buyer-info-value">
                  {{ ticket.attendee_ip_city }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">IP CITY:</div>
                <div class="buyer-info-value">
                  {{ ticket.attendee_ip_city }}
                </div>
              </div>

              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">IP P/S:</div>
                <div class="buyer-info-value">
                  {{ ticket.attendee_ip_address }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">IP COUNTRY:</div>
                <div class="buyer-info-value">
                  {{ ticket.attendee_ip_country }}
                </div>
              </div>
            </div>

            <h3 class="modal-header-title modal-header-info">
              MORE TICKET INFORMATION
            </h3>

            <div class="row">
              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">TICKET $ -FEES:</div>
                <div class="buyer-info-value">$ {{ ticket.revenue }}</div>
              </div>
              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">SOLD BY:</div>
                <div class="buyer-info-value">
                  {{ ticket.sold_by }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">PURCHASE DATE:</div>
                <div class="buyer-info-value">
                  {{ ticket.date }}
                </div>
              </div>
              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">CASH:</div>
                <div class="buyer-info-value">
                  {{ ticket.cash }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">COMP:</div>
                <div class="buyer-info-value">
                  {{ ticket.comp }}
                </div>
              </div>
              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">PROMO CODE:</div>
                <div class="buyer-info-value">
                  {{ ticket.promo }}
                </div>
              </div>
            </div>

            <h3 class="modal-header-title modal-header-info">MISCELLANEOUS</h3>
            <div class="row">
              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">PRODUCTS:</div>
                <div class="buyer-info-value">
                  {{ ticket.has_products }}
                </div>
              </div>
              <div class="col half buyer-info-inner-container">
                <div class="buyer-info-description">NOTES:</div>
                <div class="buyer-info-value">
                  {{ ticket.notes }}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-header bottom">
            <div class="modal-btn-ctn">
              <diyobo-button
                class="close"
                :type="'primary'"
                txt="Close"
                @click="$emit('close')"
              />
            </div>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { disableBodyScroll } from "body-scroll-lock";
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faTimes);

export default {
  name: "buyer-info-modal",
  components: {
    DiyoboButton,
    DiyoboInput,
    FontAwesomeIcon
  },
  props: {
    ticket: Object
  },
  data() {
    return {
      currencySign: "$"
    };
  },
  methods: {
    checkDetail(str, type) {
      switch (type) {
        case "refunded":
        case "product":
        case "comp":
          return str ? "yes" : "no";
        case "payment": {
          return str ? "cash" : "electronic";
        }
        case "ticket_type":
          return str === "IN_PERSON"
            ? "In Person"
            : str[0].toUpperCase() + str.slice(1).toLowerCase();
        case "note":
          return str === "" ? "No Available Notes" : str;
        case "ip":
          return str === "" ? "Details is Not Available" : str;
        default:
          return str === "" || str === undefined || str == null
            ? "omitted"
            : str;
      }
    },
    beforeEnter(el) {
      el.style.opacity = 0;
      let targetElement = document.querySelector("#modal-scroll-container");
      disableBodyScroll(targetElement);
    },
    enter(el) {
      el.style.opacity = 1;
    },
    leave(el) {
      el.style.opacity = 0;
    }
  }
};
</script>

<style lang="less" scoped>
.buyer-info-inner-container {
  margin-bottom: 20px;
}
.buyer-info-description {
  font-size: 16px;
  font-weight: 700;
}
.buyer-info-value {
}
::-webkit-scrollbar {
  width: 3px;
  color: #000000;
}
::-webkit-scrollbar-track {
  display: none;
}
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: 0.4s;

  .modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #3333339d;

    .modal-container {
      margin: auto;
      padding: 2px;
      position: relative;
      width: 96%;
      max-width: 700px !important;
      background-color: #4a639e;
      background-image: linear-gradient(160deg, #4eb95e, #4a639e);
      box-shadow: 1px 1px 8px 2px #111111c2;
      border-radius: 8px;
      transition: top 0.5s ease;

      #remove-pop-up {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
      }

      .modal-header,
      .modal-body {
        width: calc(100% - 40px);
        min-height: 50px;
      }

      .modal-header {
        display: flex;
        align-items: center;
        height: 80px;
        text-align: center;
        padding: 2px 20px;
        border-radius: 7px 7px 0 0;
        background-color: var(--navbar-background);

        .modal-header-title {
          width: 100%;
          margin: auto;
          height: fit-content;
        }
        .modal-header-text {
          width: 100%;
          margin: 0;
          margin-bottom: 15px;
          height: fit-content;
        }
      }

      .bottom {
        border-radius: 0px 0px 7px 7px;
      }
      .modal-body {
        padding: 0em 20px;
        padding-top: 1em;
        padding-bottom: 1em;
        background-color: var(--content-background);
        max-height: 425px;
        overflow: auto;

        .modal-body-title {
          margin-top: 0px;
        }

        .modal-body-content {
          margin: 7.5px 0px;
        }

        .modal-body-container {
          margin-bottom: 0.75em;
          color: #e60022;
          text-align: center;
          font-weight: 600;
        }
      }

      .modal-btn-ctn {
        display: flex;
        justify-content: space-evenly;
        padding: 15px 0px;
        width: 100%;
      }
    }
  }
}
</style>
