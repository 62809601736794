<template>
  <TTDialog :active="active" v-on:close="close" :width="650" :height="400">
    <v-app>
      <v-card>
        <v-card-title>
          <span class="text-h5 font-weight-bold mb-2">Refund Order</span>
          <span class="text-subtitle-2" style="word-break: normal;">
            <b>IMPORTANT NOTE:</b>
            Refunds will be added into a queue and may not be reflected
            immediately in the table.
          </span>
        </v-card-title>

        <v-card-text>
          <div class="row">
            <div class="detail col half">
              <formulate-input
                label="Refund Type"
                type="select"
                :options="[
                  { value: 'full', label: 'Full' },
                  { value: 'partial', label: 'Partial' }
                ]"
                v-model="refundType"
              ></formulate-input>
            </div>
            <div class="detail col half"></div>
          </div>
          <div class="row">
            <div class="detail col half" v-if="refundType === 'partial'">
              <formulate-input
                label="Refund Amount"
                v-model="refundAmount"
                :value="order.price_paid"
                type="text"
              ></formulate-input>
            </div>
            <div class="detail col half" v-else>
              <formulate-input
                label="Refund Amount"
                type="text"
                disabled
                :value="order.price_paid"
              ></formulate-input>
            </div>
            <div class="detail col half"></div>
          </div>
        </v-card-text>
        <v-card-actions
          v-if="this.$store.state.user.activeBrand.subdomain == 'nmp'"
        >
          <v-spacer></v-spacer>
          <v-btn color="var(--primary)" @click="refund = true">
            Save
          </v-btn>
        </v-card-actions>
        <v-card class="rounded-lg">
          <div class="col d-flex justify-center flex-wrap mt-4" v-if="refund">
            <p style="width:100%" class="text-center">
              Are you sure you want to refund this order?
            </p>
            <v-card-actions>
              <v-btn color="var(--primary)" @click="refund = false">
                Cancel
              </v-btn>
              <v-btn color="var(--primary)" @click="issueRefund">
                Confirm
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-card>
    </v-app>
  </TTDialog>
</template>

<script>
import TTDialog from "@/components/TTDialog.vue";

export default {
  name: "refund-dialog",
  components: {
    TTDialog
  },
  props: {
    isReservedSeatsType: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      refundType: "full",
      refundAmount: "0",
      active: false,
      order: null,
      tab: 0,
      edits: false,
      refund: false,
      saveButton: false,
      newPromoter: "",
      newPromoterValue: ""
    };
  },
  computed: {
    saveBtnTxt() {
      if (this.edits) {
        return "Save";
      } else {
        return "Edit Order";
      }
    }
  },
  methods: {
    open(order) {
      this.order = order;

      setTimeout(() => {
        this.active = true;
      }, 50);
    },
    close() {
      this.active = false;
    },
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    issueRefund() {
      this.order.refundAmount = this.refundAmount;
      this.order.refundType = this.refundType;

      this.$emit("issueRefund", this.order);
      this.refund = false;
      this.close();
      // alert("Refund");
    }
  }
};
</script>

<style lang="less" scoped>
.detail {
  display: flex;
  flex-direction: column;
}
</style>
